import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../../components/layout/layout"
import SEO from "../../components/seo/seo"
import Banner from "../../components/banner/banner"
import Links from "../../components/services/links/links"

export default function CivilLitigation({ data }) {
  return (
    <Layout>
      <SEO title="Civil Litigation" />
      <Banner
        image={
          <Img
            fluid={data.placeholderImage.childImageSharp.fluid}
            style={{ height: "100%" }}
          />
        }
        title="Services"
      />
      <div className="width75">
        <h1 className="title">Civil Litigation</h1>
        <Links type="about" />
        <div className="ribbon-top">
          <p>
            Civil litigation disputes arise for a wide range of reasons and it
            pays to have an experienced solicitor on your side to reach an
            informed resolution with minimised stress.
          </p>
          <p>
            At JJ Law Chambers, our civil litigation solicitors draw on decades
            of experience supporting individuals, families and businesses across
            the UK and abroad. Whether you’re issuing a claim or defending civil
            litigation proceedings, we’re here to fight your corner and
            endeavour to achieve the best possible outcome for you.
          </p>
          <p>
            We also appreciate that budget is important which is why we can
            offer suitable fee-structures from the outset. An initial
            consultation will help determine the validity of your case and the
            legal options available to you.
          </p>
          <p>
            Our solicitors will, wherever possible, seek to resolve disputes
            through formal mediation or roundtable negotiation. In the event a
            suitable outcome cannot be achieved, by this or any other pre-trial
            method, we are more than prepared to pursue court action on your
            behalf to defend your legal rights and secure the best possible
            outcome, including any compensation due. You can rest assured your
            case is being handled by an experienced civil litigation solicitor.
          </p>
          <p>
            Our team is made up of specialist Litigation Solicitors with
            expertise in the following areas:
          </p>
          <div className="center">
            <ul>
              <li>County Court Actions</li>
              <li>Debt Recovery</li>
              <li>Inheritance Claims/Contested Wills</li>
              <li>Construction/Building Disputes</li>
              <li>Defendant Personal Injury Claims</li>
              <li>Consumer Law</li>
              <li>Contract Disputes</li>
              <li>
                Neighbour/Boundary Disputes (rights of way; land disputes)
              </li>
              <li>Professional Negligence</li>
            </ul>
          </div>
          <Img
            fluid={data.bottomImage.childImageSharp.fluid}
            style={{ height: "150px" }}
            imgStyle={{ objectPosition: "center 20%" }}
          />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    placeholderImage: file(relativePath: { eq: "tower-bridge.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bottomImage: file(
      relativePath: { eq: "figure-justice-holding-scales-sword.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
